<template>
    <b-sidebar id="create-user-sidebar" :visible="isCreateUserSidebarActive" bg-variant="white"
               sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
               @change="(val) => $emit('update:is-create-user-sidebar-active', val)">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    New User
                </h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>

            </div>

            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

                    <validation-provider #default="validationContext" name="Full Name" rules="required">
                        <b-form-group label="Full Name" label-for="full-name">
                            <b-form-input id="full-name" v-model="userData.name" autofocus
                                          :state="getValidationState(validationContext)" trim placeholder="John Doe"/>

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="Email" rules="required|email">
                        <b-form-group label="Email" label-for="email">
                            <b-form-input id="email" v-model="userData.email"
                                          :state="getValidationState(validationContext)"
                                          placeholder="example@gameguru.net" trim/>

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="User Role" rules="required">
                        <b-form-group label="User Role" label-for="user-role"
                                      :state="getValidationState(validationContext)">
                            <v-select v-if="roleOptions" v-model="userData.role"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="roleOptions" placeholder="Select Role" :clearable="true"
                                      input-id="user-role" multiple/>
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <div class="d-flex mt-2">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
                                  type="submit">
                            Add
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                                  @click="hide">
                            Cancel
                        </b-button>
                    </div>

                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref} from '@vue/composition-api'
import {required, alphaNum, email} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useUserListApi from '@/composables/useUserListApi'

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        ToastificationContent,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isCreateUserSidebarActive',
        event: 'update:is-create-user-sidebar-active',
    },
    props: {
        isCreateUserSidebarActive: {
            type: Boolean,
            required: true,
        },
        roleOptions: {},

    },
    data() {
        return {
            required,
            alphaNum,
            email,
        }
    },
    setup(props, {emit}) {

        const blankUserData = {
            provider: 'google',
            name: '',
            email: '',
            role: null,
        }

        const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
        const resetuserData = () => {
            userData.value = JSON.parse(JSON.stringify(blankUserData))
        }

        const onSubmit = () => {
            store.dispatch('userStore/addUser', {
                provider: userData.value.provider,
                name: userData.value.name,
                email: userData.value.email,
                role: userData.value.role
            }).then((response) => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Successfully added!',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    emit('refetch-data')
                    emit('update:is-create-user-sidebar-active', false)
                }).catch((errors) => {
                alert(errors.message)
            })
        }

        const {
            toast
        } = useUserListApi()

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation(resetuserData)

        return {
            userData,
            onSubmit,

            refFormObserver,
            getValidationState,
            resetForm,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#create-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
