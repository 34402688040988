<template>
	<div>
		<user-list-create :is-create-user-sidebar-active.sync="isCreateUserSidebarActive" :role-options="roleOptions"
			@refetch-data="refetchData" />
		<user-list-edit :is-edit-user-sidebar-active.sync="isEditUserSidebarActive" :role-options="roleOptions"
			:discordUserList="discordUserList" :bitbucketUserList="bitbucketUserList" :asanaUserList="asanaUserList"
			:editUser="editUser" @refetch-data="refetchData" />
		<b-card no-body class="mb-0">
			<div class="m-2">
				<b-row>
					<b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Show</label>
						<v-select v-model="perPage"
							:options="perPageOptions" :clearable="false"
							class="per-page-selector d-inline-block mxz-50 ml-1" />
					</b-col>
					<b-col cols="12" md="8">
						<b-row>
							<b-col class="mb-md-0 mb-2" cols="12 " md="3">
								<v-select v-if="teamOptions" v-model="teamFilter" :options="teamOptions" placeholder="Select Team"
									:clearable="true" :reduce="(option) => option.key" label="label" class="w-100" />
							</b-col>
							<b-col class="mb-md-0 mb-2" cols="12 " md="3">
								<v-select v-if="roleOptions" :options="roleOptions" :reduce="(option) => option.value"
									v-model="roleFilter" placeholder="Select Role" class="w-100" />
							</b-col>
							<b-col class="mb-md-0 mb-2" cols="12 " md="3">
								<b-form-input v-model="searchQuery" class="d-inline-block mr-1"
									placeholder="Search..." />
							</b-col>
							<b-col class="mb-md-0 mb-2" cols="12 " md="3" v-if="$can('read', 'userCreate')">
								<b-button class="w-100" variant="primary" @click="isCreateUserSidebarActive = true">
									<span class="text-nowrap">New User</span>
								</b-button>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>
			<b-table ref="refUserListTable" class="position-relative" :items="fetchUsers" responsive
				:fields="tableColumns" striped primary-key="id" :sort-by.sync="sortBy" show-empty
				empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
				<template #table-busy>
					<div class="text-center my-2">
						<b-spinner class="d-block mx-auto mb-50"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
				<!-- Column: id -->
				<template #cell(id)="data">
					<span class="align-text-top text-capitalize">{{ data.item.id }}</span>
				</template>
				<!-- Column: User -->
				<template #cell(name)="data">
					<b-media vertical-align="center">
						<template #aside>
							<b-avatar size="32" :src="data.item.image" :text="avatarText(data.item.name)" />
						</template>
						<b-link class="font-weight-bold d-block text-nowrap">
							{{ data.item.name }}
						</b-link>
						<small class="text-muted">{{ data.item.email }}</small>
					</b-media>
				</template>
				<!-- Column: Discord Profile -->
				<template #cell(discord_user_id)="data">
					<b-badge pill class="text-capitalize" v-if="data.item.discord_user_id" variant="light-success">
						<feather-icon icon="CheckIcon" size="12" />
					</b-badge>
					<b-badge pill class="text-capitalize" v-else variant="light-primary">
						<feather-icon icon="XIcon" size="12" />
					</b-badge>
				</template>
				<!-- Column: Bitbucket Profile -->
				<template #cell(bitbucket_user_id)="data">
					<b-badge pill class="text-capitalize" v-if="data.item.bitbucket_user_id" variant="light-success">
						<feather-icon icon="CheckIcon" size="12" />
					</b-badge>
					<b-badge pill class="text-capitalize" v-else variant="light-primary">
						<feather-icon icon="XIcon" size="12" />
					</b-badge>
				</template>
				<!-- Column: Asana User Profile -->
				<template #cell(asanaUser)="data">
					<b-badge pill class="text-capitalize" v-if="data.item.asanaUser" variant="light-success">
						<feather-icon icon="CheckIcon" size="12" />
					</b-badge>
					<b-badge pill class="text-capitalize" v-else variant="light-primary">
						<feather-icon icon="XIcon" size="12" />
					</b-badge>
				</template>
				<!-- Column: Role -->
				<template #cell(role)="data">
					<b-badge pill class="text-capitalize mr-25" v-for="roles in data.item.roleList" :key="roles.id">
						{{ roles.label }}
					</b-badge>
				</template>
				<!-- Column: Provider -->
				<template #cell(team)="data">
					<span class="align-text-top text-capitalize" v-if="data.item.team">{{ data.item.team.name }}</span>
					<span class="align-text-top text-capitalize" v-else>-</span>

				</template>
				<!-- Column: Created_at -->
				<template #cell(created_at)="data">
					<span class="align-text-top text-capitalize">{{ dateFormat(data.item.created_at) }}</span>
				</template>
				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<b-link v-if="$can('read', 'userEdit')" class="mr-1" v-b-tooltip.hover.top="'Edit User'"
						@click="fncEditUserSidebar(data.item.id)">
						<feather-icon icon="EditIcon" size="18" />
					</b-link>

					<b-link v-if="$can('read', 'userDelete')" class="mr-1" v-b-tooltip.hover.top="'Delete User'"
						@click="confirmDelete(data.item.id)">
						<feather-icon icon="TrashIcon" size="18" />
					</b-link>
				</template>
			</b-table>
			<div class="mx-2 mb-2">
				<b-row>
					<b-col cols="12" sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
							entries</span>
					</b-col>
					<b-col cols="12" sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">
						<b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number
							last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUserListApi from '@/composables/useUserListApi'
import UserListCreate from './UserListCreate.vue'
import UserListEdit from './UserListEdit.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	VBTooltip,
	BSpinner
} from 'bootstrap-vue'
export default {
	components: {
		BSpinner,
		UserListCreate,
		UserListEdit,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		ToastificationContent,
		vSelect,
	},
	directives: {
		'b-tooltip': VBTooltip,
		Ripple,
	},
	setup() {
		const isCreateUserSidebarActive = ref(false)
		const isEditUserSidebarActive = ref(false)
		const isEditUserId = 'ref(null)'
		const editUser = ref(null)
		const {
			fetchUsers,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserListTable,
			refetchData,
			fetchUserRoles,
			roleOptions,
			roleFilter,
			fetchDiscordUserList,
			discordUserList,
			fetchBitbucketUserList,
			bitbucketUserList,
			fetchAsanaUserList,
			asanaUserList,
			fetchTeams,
			teamFilter,
			teamOptions
		} = useUserListApi()
		return {
			isCreateUserSidebarActive,
			isEditUserSidebarActive,
			isEditUserId,
			fetchUsers,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserListTable,
			refetchData,
			fetchUserRoles,
			avatarText,
			roleOptions,
			roleFilter,
			editUser,
			fetchDiscordUserList,
			discordUserList,
			fetchBitbucketUserList,
			bitbucketUserList,
			fetchAsanaUserList,
			asanaUserList,
			fetchTeams,
			teamFilter,
			teamOptions
		}
	},
	mounted() {
		this.fetchUserRoles()
		this.fetchDiscordUserList()
		this.fetchBitbucketUserList()
		this.fetchAsanaUserList()
		this.fetchTeams()
	},
	methods: {
		dateFormat(date) {
			return moment(date).format('YYYY-MM-DD HH:mm')
		},
		fncEditUserSidebar(id) {
			this.isEditUserSidebarActive = true;
			this.$store.dispatch('userStore/fetchUser', {
				id: id
			}).then((response) => {
				const {
					data
				} = response.data
				this.editUser = data
			})
		},
		confirmDelete(id) {
			this.$swal.fire({
				title: 'Are you sure?',
				text: "Are you sure you want to delete the user?",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete!',
				customClass: {
					confirmButton: 'btn btn-primary mr-1',
					cancelButton: 'btn btn-outline-primary ms-1'
				},
				buttonsStyling: false
			}).then(result => {
				if (result.value) {
					this.$store.dispatch('userStore/deleteUser', {
						id: id
					}).then(() => {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Successfully deleted!',
								icon: 'CheckIcon',
								variant: 'success',
							},
						})
					}).then(() => {
						this.refetchData()
					})
				}
			}).catch((error) => {
				console.log(error);
				this.refetchData()
			})
		}
	},
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
