<template>
	<b-sidebar id="edit-user-sidebar" :visible="isEditUserSidebarActive" bg-variant="white" sidebar-class="sidebar-lg"
		shadow backdrop no-header right @hidden="resetForm"
		@change="(val) => $emit('update:is-edit-user-sidebar-active', val)">
		<template #default="{ hide }">
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					Edit User
				</h5>
				<feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
			</div>
			<validation-observer #default="{ handleSubmit }" ref="refFormObserver" v-if="editUser">
				<b-form class="p-2" @submit.prevent="handleSubmit(updateSubmitUser)" @reset.prevent="resetForm">
					<b-form-group label="Team">
						<v-select v-if="teams" v-model="editUser.team_id" :options="teams" placeholder="Select Team"
							:clearable="true" :reduce="(option) => option.id" label="name" />
					</b-form-group>
					<hr />

					<validation-provider #default="validationContext" name="Full Name" rules="required">
						<b-form-group label="Full Name" label-for="full-name">
							<b-form-input id="full-name" v-model="editUser.name" autofocus
								:state="getValidationState(validationContext)" trim placeholder="John Doe" />

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<validation-provider #default="validationContext" name="Email" rules="required|email">
						<b-form-group label="Email" label-for="email">
							<b-form-input id="email" v-model="editUser.email"
								:state="getValidationState(validationContext)" placeholder="example@gameguru.net"
								trim />

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<validation-provider #default="validationContext" name="User Role" rules="required">
						<b-form-group label="User Role" label-for="user-roleList"
							:state="getValidationState(validationContext)">
							<v-select v-if="roleOptions" v-model="editUser.roleList"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="roleOptions"
								placeholder="Select Role" :clearable="true" input-id="user-roleList" multiple />
							<b-form-invalid-feedback :state="getValidationState(validationContext)">
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<b-form-group label="Discord User List" label-for="user-discord-list">
						<v-select v-if="discordUserList" v-model="editUser.discord_user_id"
							:options="sortedDiscordUsers" :reduce="(option) => option.key"
							placeholder="Select Discord User" :clearable="true" input-id="user-discord-list" />
					</b-form-group>
					<b-form-group label="Bitbucket User List" label-for="user-bitbucket-list">
						<v-select v-if="bitbucketUserList" v-model="editUser.bitbucketList"
							:options="sortedBitbucketUsers" placeholder="Select Bitbucket User" :clearable="true"
							input-id="user-bitbucket-list" multiple />
					</b-form-group>

					<b-form-group label="Asana User List">
						<v-select v-if="asanaUserList" v-model="editUser.asanaUser" :options="sortedAsanaUsers"
							placeholder="Select Asana User" :clearable="true" :reduce="(option) => option.key" />
					</b-form-group>

					<div class="d-flex mt-2">
						<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
							type="submit">
							Update
						</b-button>
						<b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
							@click="hide">
							Cancel
						</b-button>
					</div>
				</b-form>
			</validation-observer>
		</template>
	</b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useUserListApi from '@/composables/useUserListApi'
import { mapState } from 'vuex'
import {
	BSidebar,
	BForm,
	BFormGroup,
	BFormInput,
	BFormInvalidFeedback,
	BButton,
} from 'bootstrap-vue'
export default {
	components: {
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
		BFormInvalidFeedback,
		BButton,
		vSelect,
		ToastificationContent,
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		Ripple,
	},
	model: {
		prop: 'isEditUserSidebarActive',
		event: 'update:is-edit-user-sidebar-active',
	},
	props: {
		isEditUserSidebarActive: {
			type: Boolean,
			required: true,
		},
		roleOptions: {},
		editUser: [],
		discordUserList: [],
		bitbucketUserList: [],
		asanaUserList: [],
	},
	data() {
		return {
			required,
			alphaNum,
			email,
		}
	},
	setup(props, {emit}) {
		const blankUserData = {
			name: '',
			email: '',
			roleList: [],
		}
		const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
		const resetuserData = () => {
			userData.value = JSON.parse(JSON.stringify(blankUserData))
		}
		const updateSubmitUser = () => {
			store.dispatch('userStore/updateUser', {
					provider: 'google',
					id: props.editUser.id,
					name: props.editUser.name,
					email: props.editUser.email,
					role: props.editUser.roleList,
					discord_user_id: props.editUser.discord_user_id,
					bitbucket_user_id: props.editUser.bitbucketList,
					asana_user_id: props.editUser.asanaUser,
					team_id: props.editUser.team_id,
				})
				.then((response) => {
					toast({
						component: ToastificationContent,
						props: {
							title: 'Successfully updated!',
							icon: 'CheckIcon',
							variant: 'success',
						},
					})
					emit('refetch-data')
					emit('update:is-edit-user-sidebar-active', false)
				}).catch((errors) => {
					alert(errors.message)
				})
		}
		const {
			toast
		} = useUserListApi()
		const {
			refFormObserver,
			getValidationState,
			resetForm,
		} = formValidation(resetuserData)
		return {
			userData,
			updateSubmitUser,
			refFormObserver,
			getValidationState,
			resetForm,
		}
	},
	computed: {
		sortedDiscordUsers: function () {
			return this.discordUserList.sort((a, b) => a.label.localeCompare(b.label));
		},
		sortedBitbucketUsers: function () {
			return this.bitbucketUserList.sort((a, b) => a.label.localeCompare(b.label));
		},
		sortedAsanaUsers: function () {
			return this.asanaUserList.sort((a, b) => a.label.localeCompare(b.label));
		},
		...mapState('userStore', ['teams', 'isLoading'])
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
