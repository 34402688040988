import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUserListApi() {
	const toast = useToast()
	const refUserListTable = ref(null)
	const discordUserList = ref(null)
	const bitbucketUserList = ref(null)
	const asanaUserList = ref(null)
	const perPage = ref(localStorage.getItem('userListPerPage')??10)
	const totalUsers = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10,15, 20, 100]
	const searchQuery = ref('')
	const sortBy = ref('id')
	const isSortDirDesc = ref(true)
	const roleFilter = ref(null)
	const planFilter = ref(null)
	const statusFilter = ref(null)
	const teamFilter = ref(null)
	const teamOptions = ref(null)
	const roleOptions = ref(null)
	const unpairedUsers  = ref(null)
	const tableColumns = [
		{ key: 'id', sortable: true },
		{ key: 'team', sortable: false },
		{ key: 'name', sortable: true, label:"User" },
		{ key:'discord_user_id',label:'Discord Profile',sortable:true},
		{ key:'bitbucket_user_id',label:'Bitbucket Profile',sortable:true},
		{ key:'asanaUser',label:'Asana Profile',sortable:false},
		{ key: 'role', sortable: false },
		{ key: 'created_at', sortable: true },
		{ key: 'actions' },
	]
	const dataMeta = computed(() => {
		const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
		return {
		from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
		to: perPage.value * (currentPage.value - 1) + localItemsCount,
		of: totalUsers.value,
		}
	})
	const refetchData = () => {
		localStorage.setItem('userListPerPage',perPage.value)
		refUserListTable.value.refresh()
	}
	watch([currentPage, perPage, searchQuery, roleFilter,teamFilter], () => {
		refetchData()
	})
	const fetchUsers = (ctx, callback) => {
		let sort = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
		store
		.dispatch('userStore/fetchUsers', {
			sort: sort,
			q: searchQuery.value,
			perPage: perPage.value,
			page: currentPage.value,
			sortBy: sortBy.value,
			role: roleFilter.value,
			team:teamFilter.value,
			pagination:true
		})
		.then(response => {
			const { data, total } = response.data
			callback(data)
			totalUsers.value = response.data.pagination.total
		})
		.catch(() => {
			toast({
			component: ToastificationContent,
			props: {
				title: 'Error',
				icon: 'AlertTriangleIcon',
				variant: 'danger',
				text:'Oops! Something went wrong!',
			},
			})
		})
	}
	const fetchUserRoles = (ctx, callback) => {
		store.dispatch('userStore/fetchUserRoles', {})
			.then(response => {
				const mapFnc =   response.data
				roleOptions.value = mapFnc.data.map(item => ({label: item.name, value: item.name }))
			}).catch(error => {console.log(error)})
	}
	// ------------------------------------------------
	// Get Discord User List
	// ------------------------------------------------
	const fetchDiscordUserList = (ctx, callback) => {
	store
		.dispatch('discordStore/fetchDiscordAllUsers', ).then(response => {
			const {
				data
			} = response.data
			discordUserList.value = data.map(item => ({
				key: item.id,
				label: item.name
			}))
		}).catch(error => {
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					text:'Oops! Something went wrong!',
				},
			})
		})
	}

	// ------------------------------------------------
	// Get Bitbucket User List
	// ------------------------------------------------
	const fetchBitbucketUserList = (ctx, callback) => {
		store
			.dispatch('bitbucketStore/fetchBitbucketAllUsers', ).then(response => {
				const {
					data
				} = response.data
				bitbucketUserList.value = data.map(item => ({
					key: item.id,
					label: item.name
				}))
			}).catch(error => {
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
						text:'Oops! Something went wrong!',
					},
				})
			})
	}

	// ------------------------------------------------
	// Get Asana User List
	// ------------------------------------------------
	const fetchAsanaUserList = (ctx, callback) => {
		store
			.dispatch('asanaStore/fetchAsanaUsers', ).then(response => {
				const {
					data
				} = response.data
				asanaUserList.value = data.map(item => ({
					key: item.id,
					label: item.name
				}))
			}).catch(error => {
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
						text:'Oops! Something went wrong!',
					},
				})
			})
	}
	// ------------------------------------------------
	// Get Unpaired Users
	// ------------------------------------------------
	const fetchUnpairedUsers = (ctx, callback) => {
	store
		.dispatch('userStore/fetchUnpairedUsers', ).then(response => {
		const {data} = response.data
		unpairedUsers.value = data
		}).catch(error => {
		toast({
			component: ToastificationContent,
			props: {
			title: 'Error',
			icon: 'AlertTriangleIcon',
			variant: 'danger',
			text: 'Oops! Something went wrong!',
			},
		})
		})
	}
	// ------------------------------------------------
	// Get Teams
	// ------------------------------------------------
	const fetchTeams = (ctx, callback) => {
		store
		.dispatch('userStore/fetchTeams', ).then(response => {
			const mapFnc =   response.data
			teamOptions.value = mapFnc.data.map(item => ({key: item.id, label: item.name }))
		}).catch(error => {
			console.log(error);
			toast({
			component: ToastificationContent,
			props: {
				title: 'Error',
				icon: 'AlertTriangleIcon',
				variant: 'danger',
				text: 'Oops! Something went wrong!',
			},
			})
		})
	}

	return {
		fetchUsers,
		fetchUserRoles,
		tableColumns,
		perPage,
		currentPage,
		totalUsers,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refUserListTable,
		toast,
		roleOptions,
		refetchData,
		roleFilter,
		planFilter,
		statusFilter,
		fetchDiscordUserList,
		discordUserList,
		fetchBitbucketUserList,
		bitbucketUserList,
		fetchUnpairedUsers,
		unpairedUsers,
		fetchAsanaUserList,
		asanaUserList,
		fetchTeams,
		teamFilter,
		teamOptions
	}
}
